<template>
  <div>
    <div class="check-step bg-white d-flex shadow-sm">
      <div class="item active">
        <span>01.</span>
        <span>{{ $t("CART.STEP1") }}</span>
        <span class="material-icons-outlined">navigate_next</span>
      </div>
      <div class="item">
        <span>02.</span>
        <span>{{ $t("CART.STEP2") }}</span>
        <span class="material-icons-outlined">navigate_next</span>
      </div>
      <div class="item">
        <span>03.</span>
        <span>{{ $t("CART.STEP3") }}</span>
      </div>
    </div>

    <div class="check-cart">
      <div class="menu-list" v-if="cartList.length > 0">
        <div class="item" v-for="cart in cartList" :key="cart.id">
          <div class="row">
            <app-image :image_url="cart.product.image_url"></app-image>
            <div class="col-8">
              <div class="title row no-gutters justify-content-between align-items-center">
                <h3>{{ cart.product.title }}</h3>
              </div>
              <div class="introduction">{{ cart.addition_products }}</div>

              <product-price :item="{ ...cart, cartVip: cart.product.is_vip }"></product-price>
            </div>
          </div>
          <div class="row justify-content-end mt-2">
            <div class="col">
              <div class="input-group mb-3">
                <el-input-number
                  v-model="cart.quantity"
                  @change="
                    (oldval, newval) => handleChange(oldval, newval, cart.id)
                  "
                  label="Quantity"
                  :ref="
                    (el) => {
                      if (el) quantity[cart.id] = el;
                    }
                  "
                  :max="99"
                ></el-input-number>
              </div>
            </div>
          </div>
        </div>
        <div class="cart-summary row no-gutters justify-content-end">
          <div class="col-auto p-2">
            <span>{{ $t("CART.TOTAL.GOODS") }}：</span>
            <span class="text-primary">{{ cartQuantity }}</span>
            <span>{{ $t("CART.TOTAL.GOODS_UNIT") }}</span>
          </div>
          <div class="col-auto p-2">
            <span>{{ $t("CART.TOTAL.MONEY") }}</span>
            <span class="text-primary">$ {{ formatMoney(cartTotal) }}</span>
            <span>{{ $t("CART.TOTAL.MONEY_UNIT") }}</span>
          </div>
        </div>
      </div>
    </div>

    <nav class="checkout-menu">
      <div class="row no-gutters justify-content-center w-100 fixed-bottom bg-light">
        <div class="col px-3">
          <router-link
            :to="'/home/' + category_id"
            class="btn bg-dark btn-block shadow text-white my-2"
            id="optionClose"
          >{{ $t("CART.BUTTON.BACK") }}</router-link>
        </div>
        <div class="col px-3" :disabled="isUpdating">
          <router-link
            to="/checkout"
            class="btn bg-main btn-block shadow text-white my-2"
          >{{ $t("CART.BUTTON.NEXT") }}</router-link>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { inject } from "vue";
import { useStore } from "vuex";
import { computed, getCurrentInstance, ref, onBeforeUpdate } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { formatMoney } from "@/utils/tools";
import storage from "@u/storage";
import AppImage from "@/components/AppImage.vue";
import ProductPrice from "@/components/ProductPrice.vue";
import Swal from "sweetalert2";
storage.config = { type: "session" };

export default {
  name: "CartsView",
  components: {
    AppImage,
    ProductPrice,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  emits: ["onLoading"],
  setup(props, { emit }) {
    const shop_name = inject("shop_name");
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const router = useRouter();
    const category_id = storage.getItem("category_id", { type: "session" });

    //computed: getter
    const isLoading = computed(() => store.getters["carts/isLoading"]);
    const myIsLoading = computed(() => {
      emit("onLoading", isLoading.value);
      return props.isLoading;
    });
    const errorCart = computed(() => store.getters["carts/errorCart"]);
    const setErrorCart = (val) => {
      store.commit("carts/setErrorCart", val);
    };

    //一開始就要取資料
    store.dispatch("carts/getCarts").then(() => {
      if (cartList.value.length === 0) {
        if (storage.getItem("category_id") > 0)
          router.push("/home/" + storage.getItem("category_id"));
        else router.push("/home");
      }
    });
    //數量
    const cartQuantity = computed(() => store.getters["carts/cartQuantity"]);
    //總金額
    const cartTotal = computed(() => store.getters["carts/cartTotal"]);
    //購物車資料
    const cartList = computed(() => store.getters["carts/cartList"]);
    const isUpdating = computed(() => store.getters["carts/isUpdating"]);
    const updatedData = computed(() => store.getters["carts/updatedData"]);

    //methods
    const quantity = ref([]);
    onBeforeUpdate(() => {
      quantity.value = [];
    });
    const handleChange = (newVal, oldVal, cart_id) => {
      store
        .dispatch("carts/updateCartQuantity", {
          id: cart_id,
          quantity: newVal,
        })
        .then(() => {}) //透過watch監控
        .catch(() => {
          onAlert("error");
        });
    };
    const onAlert = (type) => {
      let message =
        type === "success"
          ? t("ALERT.CART_UPDATED", { msg: t("ALERT.SUCCESS") })
          : t("ALERT.CART_UPDATED", { msg: t("ALERT.ERROR") });
      proxy.$message({
        type: type,
        message: message,
      });
    };

    return {
      shop_name,
      myIsLoading,
      cartList,
      cartQuantity,
      cartTotal,
      isUpdating,
      updatedData,
      formatMoney,
      quantity,
      handleChange,
      onAlert,
      category_id,
      errorCart,
      setErrorCart,
    };
  },
  watch: {
    cartList: function () {
      if (this.cartList.length === 0) {
        if (storage.getItem("category_id") > 0)
          this.$router.push("/home/" + storage.getItem("category_id"));
        else this.$router.push("/home");
      }
    },
    updatedData: function () {
      if (this.updatedData !== null && !this.isUpdating) {
        this.updatedData.is_updated === true
          ? this.onAlert("success")
          : this.onAlert("error");
      }
    },
    errorCart: function (newVal, oldVal) {
      console.log("購物車異常", newVal);
      if (newVal) {
        Swal.fire({
            title: "購物車異常",
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonColor: "#fd7e14",
            confirmButtonText: "確認",
          }).then((value) => {
            this.$router.push("/home");
          });
      }
    },
  },
};
</script>
